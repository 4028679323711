import React from 'react';
import dataText from '../components/gui/gui-typo/data';
import StandardPage from '../components/page/page-standard';

const pageName = 'public-offer-page';

const itemsData = [
  {
    title: 'Общие положения',
  },
  {
    title: 'Определение терминов',
  },
  {
    title: 'Предмет соглашения',
  },
  {
    title: 'Права и обязанности сторон',
  },
  {
    title: 'Использование сайта',
  },
  {
    title: 'Ответственность',
  },
  {
    title: 'Нарушение условий пользовательского соглашения',
  },
  {
    title: 'Разрешение споров',
  },
];

// eslint-disable-next-line no-param-reassign,no-return-assign
itemsData.forEach((item) => item.text = dataText.listItem);

const PublicOfferPage = () => (
  <StandardPage pageName={pageName} title="Публичная оферта">

    <ol className="list list_num">

      {itemsData.map((item, key) => (
        <li className="list__item" key={key}>
          <div className="list__item-title page__title page__title_h2">{item.title}</div>
          <div className="list__item-body page__limited-text">{item.text}</div>
        </li>
      ))}

    </ol>

  </StandardPage>
);

export default PublicOfferPage;
